<template>
  <div class="feedback-container">
    <h2 >问题反馈</h2>
    <van-form>
      <van-cell-group inset>
        <!-- 名字输入 -->
        <van-field
            v-model="name"
            label="名字"
            placeholder="请输入你的名字"
            required
        />

        <!-- 联系邮箱 -->
        <van-field
            v-model="email"
            label="邮箱"
            placeholder="请输入联系邮箱"
            type="email"
            required
            :error="emailError"
            :error-message="emailErrorMessage"
            @blur="validateEmail"
        />

        <!-- 反馈内容 -->
        <van-field
            v-model="content"
            label="反馈内容"
            type="textarea"
            rows="4"
            placeholder="请简要描述问题"
            required
        />


        <text> 上传图片 </text>
        <!-- 文件上传 -->
        <van-uploader
            v-model="fileList"
            multiple
            max-count="3"
            max-size="500 * 1024"
            after-read="onFileRead"
        />
      </van-cell-group>

      <div class="submit-btn-wrapper">
        <van-button type="primary" round block @click="handleSubmit">
          提交反馈
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { showDialog } from 'vant';

export default {
  data() {
    return {
      name: '',
      email: '',
      content: '',
      category: '',
      priority: '',
      fileList: [],
      emailError: false,
      emailErrorMessage: '',
      showCategoryPicker: false,
      categoryOptions: ['功能建议', 'UI问题', '性能问题', '其他'],
      showPriorityActions: false,
      priorityOptions: [
        { name: '低' },
        { name: '中' },
        { name: '高' }
      ]
    };
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.email && !emailRegex.test(this.email)) {
        this.emailError = true;
        this.emailErrorMessage = '请输入正确的邮箱格式';
        return;
      }
      this.emailError = false;
      this.emailErrorMessage = '';
    },
    onCategoryConfirm(value) {
      this.category = value;
      this.showCategoryPicker = false;
    },
    onSelectPriority(action) {
      this.priority = action.name;
      this.showPriorityActions = false;
    },
    onFileRead(file) {
      console.log('Uploaded file:', file);
    },
    handleSubmit() {
      if (!this.name || !this.email || !this.content) {
        showDialog({
          message: '请填写完整信息'
        });
        return;
      }
      // 提交反馈处理逻辑
      showDialog({
        message: '感谢您的反馈，我们会及时处理。',
      }).then(() => {
        this.$router.push('/first');
      });
    }
  }
};
</script>

<style scoped>
.feedback-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.submit-btn-wrapper {
  margin-top: 16px;
}
</style>
